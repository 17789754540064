import React from 'react'
import { Link } from 'react-router-dom'
import { connect } from 'react-redux';

// import { Button, Modal, ModalBody, ModalFooter } from 'reactstrap';
// import posed from 'react-pose';

import logo from '../assets/icon.png' // relative path to image 

// import logoKWhite from '../assets/k_logo_100_white.png' // "K" logo, solid white
// import logoWide from '../assets/simcase_wide.png' // relative path to image 
// import logoKellogg from '../assets/Kellogg_H_RGB.png' // relative path to image 
// import iconDocument from '../assets/icon_document.png' // relative path to image 

/* FontAwesome */
import { library } from '@fortawesome/fontawesome-svg-core'
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTimesCircle, faInfo, faRoute, faBars, faListAlt, faPowerOff, faUserFriends, faTachometerAlt, faTrashAlt, faPlusCircle } from '@fortawesome/free-solid-svg-icons'

// Add FontAwesome icons
library.add([faTimesCircle, faInfo, faRoute, faBars, faListAlt, faPowerOff, faUserFriends, faTachometerAlt, faTrashAlt, faPlusCircle]);

// const Menu = posed.div({
//     init: {
//         opacity: 0
//     },
//     enter: { 
//         opacity: 1,
//         delayChildren: 250,
//         staggerChildren: 100
//     },
//     exit: { 
//         opacity: 0
//     }
// });

// const MenuClose = posed.div({
//     hoverable: true,
//     init: {
//         scale: 1
//     },
//     hover: {
//         scale: 1.2
//     },
//     enter: { 
//         opacity: 1, y: 0,
//         transition: { duration: 250 }
//     },
//     exit: { 
//         opacity: 0, y: -30,
//         transition: { duration: 250 }
//     }
// });

// const MenuItem = posed.div({
//     hoverable: true,
//     init: {
//         scale: 1
//     },
//     hover: {
//         scale: 1.05
//     },
//     enter: { 
//         opacity: 1, y: 0,
//         transition: { duration: 250 }
//     },
//     exit: { 
//         opacity: 0, y: 30,
//         transition: { duration: 250 }
//     }
// });


// The Header creates links that can be used to navigate
// between routes.
class Header extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            open: false,
            about: false
        }
    } 

    render(){

        const { game } = this.props;
        const title = game.data ? game.data.name : '';

        let menuItems = [
            { url: '/login', label: 'Sign-In' }, 
            { url: '/register', label: 'Sign-Up' }
        ]

        const menuLinks = menuItems.map( (l, li) => {
            return <Link to={l.url} key={`menu-item-${li}`} className={ li === menuItems.length-1 ? 'btn border-left pl-4 pr-4 border-right' : 'btn border-left pl-4 pr-4' }>
                { l.label }
            </Link>
        })

        return <header className="app-header" style={{ margin: '0 -15px' }}>
            <nav id="main-menu" className="navbar">

                <Link className="navbar-brand title mr-0 mr-md-2" to="/library">
                    <img src={logo} className="logo" style={{ height: '30px', marginRight: '10px', marginTop: '-9px' }} alt="logo" />
                    HUB {game.data && <small className="d-inline-block font-weight-light ml-4">{ title }</small> }
                </Link>

                { menuLinks }

                <div className="ml-auto mr-3">
                    
                </div>
        
            </nav>

        </header>
    }

}

function mapStateToProps(state) {
    const { player, game } = state;
    return {
        game,
        player
    };
}

export default connect(mapStateToProps)(Header);
