import { combineReducers } from 'redux';

import { alert } from './alert';
import { authentication } from './authentication';
import { registration } from './registration';
import { user } from './user';
import { users } from './users';
import { schools } from './schools';
import { clients } from './clients';
import { invites } from './invites';
import { purchases } from './purchases';
// import { settings } from './settings';
// import { games } from './games';
// import { alert } from './alert';
// import { modal } from './modal';
import { game } from './game';

const rootReducer = combineReducers({
  alert,
  authentication,
  registration,
  user,
  users,
  schools,
  clients,
  invites,
  purchases,
  game,
});

export default rootReducer;
