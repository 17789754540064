import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { connect } from 'react-redux';
// import numeral from 'numeral';
// import moment from 'moment';

// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

// Actions
// import { usersActions } from '../../actions';

import Header from '../../components/Header';
import ModuleList from './list';
import ModuleItem from './item';
// import ModuleForm from './form'

class PurchasesPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
    // Bindings
  }

  render() {
    const { match } = this.props;

    return (
      <div>
        <Header />

        <Switch>
          <Route exact path={match.path} component={ModuleList} />
          {/* <Route exact path={match.path + '/new'} component={ ModuleItem } /> */}
          <Route exact path={match.path + '/:_id'} component={ModuleItem} />
        </Switch>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {};
}

export default connect(mapStateToProps)(PurchasesPage);
