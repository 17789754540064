import React from 'react';
import { Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
// import queryString from 'query-string';

// import { playerActions, gameActions } from '../../actions';
// import { setupSocket, enterGame } from '../../helpers';

// import posed, { PoseGroup } from 'react-pose';

// import scLogo from '../assets/icon.png';
import scLogoWide from '../assets/simcase_wide.png';
// import appLogo from '../assets/app_icon.png';

import RegisterForm from '../components/RegisterForm';

class RegisterPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      data: null,
    };
  }

  render() {
    const { loggedIn } = this.props;

    if (loggedIn) return <Redirect to='/dashboard' />;

    const isMobile = window.innerWidth < 500 ? true : false;

    return (
      <div className='row bgcolorsx'>
        <div
          className='col-12 col-md-8 ml-md-auto mr-md-auto col-lg-4 ml-lg-auto mr-lg-auto text-center'
          style={{ height: '100vh' }}>
          <div className={!isMobile ? 'v-center' : ''}>
            <div className={!isMobile ? 'card shadow' : 'card border-0'}>
              <div
                className={!isMobile ? 'card-body p-5' : 'card-body p-3 pt-5'}>
                <img
                  src={scLogoWide}
                  className='mb-3 img-fluid'
                  style={{ width: '120px' }}
                  alt='SimCase'
                />
                <h3 className='mt-2 mb-4'>Sign up</h3>
                <RegisterForm />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  const { authentication } = state;
  const { user, loggedIn } = authentication;
  return {
    user,
    loggedIn,
  };
}

export default connect(mapStateToProps)(RegisterPage);
