import { gameConstants } from '../constants';

export function game(state = {}, action) {
  switch (action.type) {
    case gameConstants.SET_DATA:
      const d = action.data;
      if( d === null )
        return { data: null };
      else
        return {
          data: {...state.data, ...d}
        };
    case gameConstants.SET_GAMES:
      return {
        games: action.data
      };
    case gameConstants.ADD_PLAYERS:
        const newpls = action.data;
        const totalPlayers = [...state.data.players, ...newpls]
        return {
          data: {...state.data, ...{ players: totalPlayers }}
        };
    default:
      return state
  }
}