import React, { useState, useEffect } from 'react';
import { loadStripe } from '@stripe/stripe-js';
import {
  CardElement,
  Elements,
  ElementsConsumer,
} from '@stripe/react-stripe-js';

// import stripeLogo from '../assets/powered_by_stripe.svg'

const CARD_OPTIONS = {
  iconStyle: 'solid',
  style: {
    base: {
      iconColor: '#007bff',
      color: '#444',
      fontWeight: 500,
      fontFamily: 'Roboto, Open Sans, Segoe UI, sans-serif',
      fontSize: '16px',
      fontSmoothing: 'antialiased',
      ':-webkit-autofill': {
        color: '#fce883',
      },
      '::placeholder': {
        color: '#007bff',
      },
    },
    invalid: {
      iconColor: '#dc3545',
      color: '#dc3545',
    },
  },
};

const CardField = ({ onChange }) => (
  <div className='form-group row mb-2'>
    <div className='col-12'>
      <span className='form-control pt-3 pb-3' style={{ height: 'auto' }}>
        <CardElement options={CARD_OPTIONS} onChange={onChange} />
      </span>
    </div>
  </div>
);

const Field = ({
  label,
  id,
  type,
  placeholder,
  required,
  autoComplete,
  value,
  onChange,
}) => (
  <div className='input-group mb-2'>
    <div className='input-group-prepend'>
      <label
        htmlFor={id}
        className='input-group-text'
        style={{ width: '100px' }}>
        {label}
      </label>
    </div>
    <input
      className='form-control'
      id={id}
      type={type}
      placeholder={placeholder}
      required={required}
      autoComplete={autoComplete}
      value={value}
      onChange={onChange}
    />
  </div>
);

const SubmitButton = ({ processing, error, children, disabled }) => (
  <button
    className={`btn btn-lg btn-outline-primary mt-2 SubmitButton ${
      error ? 'SubmitButton--error' : ''
    }`}
    style={{ width: '250px' }}
    type='submit'
    disabled={processing || disabled}>
    {processing ? 'Processing...' : children}
  </button>
);

const ErrorMessage = ({ children }) => (
  <div className='alert alert-warning text-danger' role='alert'>
    <small>{children}</small>
  </div>
);

const DEFAULT_STATE = {
  error: null,
  cardComplete: false,
  processing: false,
  paymentMethod: null,
  email: '',
  phone: '',
  name: '',
};

class CheckoutForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = { ...DEFAULT_STATE, ...(props.values || {}) };
  }

  handleSubmit = async (event) => {
    event.preventDefault();

    const { stripe, elements, intent } = this.props;
    const { email, phone, name, error, cardComplete } = this.state;

    if (!stripe || !elements) {
      // Stripe.js has not loaded yet. Make sure to disable
      // form submission until Stripe.js has loaded.
      return;
    }

    if (error) {
      elements.getElement('card').focus();
      return;
    }

    if (cardComplete) {
      this.setState({ processing: true });
    }

    const billing_details = {};
    if (name.length > 0) billing_details.name = name;
    if (email.length > 0) billing_details.email = email;
    if (phone.length > 0) billing_details.phone = phone;

    const intentResult = await stripe.confirmCardPayment(intent.client_secret, {
      payment_method: {
        card: elements.getElement(CardElement),
        billing_details,
      },
    });

    this.setState({ processing: false });

    if (intentResult.error) {
      this.setState({ error: intentResult.error });
    } else {
      this.props.onSuccess && this.props.onSuccess(intentResult.paymentIntent);
      this.setState({ paymentMethod: intentResult.paymentIntent });
    }
  };

  reset = () => {
    this.setState(DEFAULT_STATE);
  };

  render() {
    const { error, processing, name, email, phone } = this.state;
    const { stripe, intent, fields } = this.props;
    const requiredFields = fields ? fields : ['name', 'email', 'phone'];

    return (
      <form className='form' onSubmit={this.handleSubmit}>
        <div className='form-group row mb-0'>
          <div className='col-10 offset-1'>
            {requiredFields.indexOf('name') > -1 && (
              <Field
                label='Name'
                id='name'
                type='text'
                placeholder='Name'
                required
                autoComplete='name'
                value={name}
                onChange={(event) => {
                  this.setState({ name: event.target.value });
                }}
              />
            )}
            {requiredFields.indexOf('email') > -1 && (
              <Field
                label='Email'
                id='email'
                type='email'
                placeholder='Email address'
                required
                autoComplete='email'
                value={email}
                onChange={(event) => {
                  this.setState({ email: event.target.value });
                }}
              />
            )}
            {requiredFields.indexOf('phone') > -1 && (
              <Field
                label='Phone'
                id='phone'
                type='tel'
                placeholder='(941) 555-0123'
                required
                autoComplete='tel'
                value={phone}
                onChange={(event) => {
                  this.setState({ phone: event.target.value });
                }}
              />
            )}
            <CardField
              onChange={(event) => {
                this.setState({
                  error: event.error,
                  cardComplete: event.complete,
                });
              }}
            />

            {error && <ErrorMessage>{error.message}</ErrorMessage>}
          </div>
        </div>

        {!intent && (
          <button
            className='btn btn-lg btn-outline-secondary mt-2'
            style={{ width: '200px' }}>
            Loading...
          </button>
        )}
        {intent && (
          <SubmitButton
            processing={processing}
            error={error}
            disabled={!stripe}>
            Confirm ${intent.amount / 100} Payment
          </SubmitButton>
        )}

        {/* <br/>
        <img src={stripeLogo} className="mt-3" style={{ width: '100px' }} alt="Powered by Stripe" /> */}
      </form>
    );
  }
}

const InjectedCheckoutForm = (props) => (
  <ElementsConsumer>
    {({ stripe, elements }) => (
      <CheckoutForm
        stripe={stripe}
        elements={elements}
        intent={props.intent}
        fields={props.fields}
        values={props.values}
        onSuccess={props.onSuccess}
      />
    )}
  </ElementsConsumer>
);

const ELEMENTS_OPTIONS = {
  fonts: [{ cssSrc: 'https://fonts.googleapis.com/css?family=Roboto' }],
};

// Make sure to call `loadStripe` outside of a component’s render to avoid
// recreating the `Stripe` object on every render.
const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PK);

const PaymentForm = (props) => {
  const [intent, setIntent] = useState(null);

  useEffect(() => {
    fetch('/api/payments/intent', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        client_id: props.client,
        quantity: props.quantity,
      }),
    })
      .then((response) => response.json())
      .then((res) => {
        setIntent(res);
      });
  }, []);

  return (
    <div className='AppWrapper'>
      <Elements stripe={stripePromise} options={ELEMENTS_OPTIONS}>
        <InjectedCheckoutForm
          intent={intent}
          fields={props.fields}
          values={props.values}
          onSuccess={props.onSuccess}
        />
      </Elements>
    </div>
  );
};

export default PaymentForm;
