import React from 'react';
import { Route } from 'react-router-dom';
import { connect } from 'react-redux';
// import numeral from 'numeral';
// import moment from 'moment';

// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

// Actions
// import { usersActions } from '../../actions';

import Header from '../../components/Header';
import HeaderStudent from '../../components/HeaderStudent';
import InvitesList from './list';
import InviteItem from './item';

class InvitesPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};

    // Bindings
  }

  render() {
    const { user, match } = this.props;

    return (
      <div>
        {user.role !== 'student' && <Header />}
        {user.role === 'student' && <HeaderStudent />}

        <Route exact path={match.path} component={InvitesList} />
        <Route exact path={match.path + '/:_id'} component={InviteItem} />
      </div>
    );
  }
}

function mapStateToProps(state) {
  const { user } = state.authentication;
  return { user };
}

export default connect(mapStateToProps)(InvitesPage);
