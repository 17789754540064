import React from 'react'

class Form extends React.Component {

    constructor(props) {

        super(props);
        this.state = {
            item: this.props.item || null,
            clients: this.props.clients || [],
            saving: 'init',
            changed: false
        }

        // Bindings
        this.handleChange = this.handleChange.bind(this)
        this.handleClientsChange = this.handleClientsChange.bind(this)
        this.submit = this.submit.bind(this)

    }

    componentDidUpdate(prevProps){
        if( this.props.clients.length !== prevProps.clients.length ){
            this.setState({ clients: this.props.clients })
        }
    }

    handleChange(e){
        let item = {...this.state.item}
        item[e.target.name] = e.target.type === 'checkbox' ? e.target.checked : e.target.value;
        item['changed'] = true;
        this.setState({ item })
    }

    handleClientsChange(e){
        let item = {...this.state.item}
        let clients = [...item.free_to_play]

        const cindex = clients.indexOf( e.target.value )
        if(cindex === -1) clients.push(e.target.value)
        else clients.splice(cindex, 1)

        item.free_to_play = clients
        item['changed'] = true;
        this.setState({ item })
    }

    submit(){
        const { item } = this.state;
        if( this.props.onSubmit )
            this.props.onSubmit(item)
    }

    render(){

        const { item, clients, status } = this.state;

        return <div>

            <h2 className="font-weight-light mt-4">
                Schools: { item.name || '' } <small 
                    style={{ fontSize: '12px', padding: '5px 8px', verticalAlign: 'middle' }} 
                    className={ status === 'saving' ? 'text-capitalize badge badge-pill badge-warning' : 'text-capitalize badge badge-pill badge-success' }>
                    { status === 'saving' ? 'saving...' : status }
                </small>
                <div className="float-right" style={{ lineHeight: 1 }}>
                    <button className="btn btn-md btn-outline-primary" onClick={ this.submit }>Save</button>
                </div>
            </h2>

            <hr/>

            <div className="row mt-4">

                <div className="col-3 offset-3">
                    <div className="form-group">
                        <label>Name</label>
                        <div className="input-group input-group-lg">
                            <input name="name" className="form-control" placeholder="Name"
                                value={ item.name || '' } onChange={this.handleChange} />
                        </div>
                    </div>
                    <div className="form-group">
                        <label>Email</label>
                        <div className="input-group input-group-lg">
                            <input name="email" className="form-control" placeholder="Email"
                                value={ item.email || '' } onChange={this.handleChange} />
                        </div>
                    </div>
                    <div className="form-group">
                        <label>Portfolio Access</label>
                        <div className="input-group input-group-lg">
                            <select name="port_access" className="form-control" value={ item.port_access || '' } onChange={this.handleChange}>
                                <option value="">None</option>
                                <option value="all">All</option>
                            </select>
                        </div>
                    </div>
                </div>

                <div className="col-3">
                    <div className="form-group">
                        <label>Code</label>
                        <div className="input-group input-group-lg">
                            <input name="code" className="form-control" placeholder="Code"
                                value={ item.code || '' } onChange={this.handleChange} />
                        </div>
                    </div>
                    <div className="form-group">
                        <label>School Icon URL (50x50 pixels)</label>
                        <div className="input-group input-group-lg">
                            <input name="icon_url" className="form-control" placeholder="Icon URL"
                                value={ item.icon_url || '' } onChange={this.handleChange} />
                        </div>
                    </div>
                    <div className="form-group">

                        <label>Free To Play</label>
                        <div className="card">
                            <div className="card-body">
                                { clients.map(c => <div key={`check-${c._id}`} className="form-check mb-2">
                                    <input 
                                        name="free_to_play"
                                        className="form-check-input" 
                                        type="checkbox" 
                                        value={c._id}
                                        id={`check-${c._id}`} 
                                        checked={item.free_to_play.indexOf(c._id) > -1 ? true : false }
                                        onClick={this.handleClientsChange} />
                                    <label className="form-check-label" htmlFor={`check-${c._id}`}>
                                        { c.name }
                                    </label>
                                </div>
                                )}
                            </div>
                        </div>

                    </div>
                </div>

            </div>

        </div>
    }

}

export default Form;