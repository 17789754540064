import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';

const PrivateRoute = ({ component: Component, loggedIn, ...rest }) => (
    <Route {...rest} render={ props => {
        // const from = encodeURIComponent(`${props.location.pathname}${props.location.search}${props.location.hash}`)
        // return null
        return (
            loggedIn
            ? <Component {...props} />
            : <Redirect to={{ 
                pathname: '/login',
                // search: '?prev='+from,
                state: { from: props.location } 
            }} />
        )}
    } />
)

const mapStateToProps = state => ({ loggedIn: state.authentication.loggedIn });
export default connect(mapStateToProps)(PrivateRoute);
