import React from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
// import { Modal, ModalBody } from 'reactstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

// Actions
import { userActions } from '../actions';

// import FormInsReq from '../components/FormInsReq'

// The Header creates links that can be used to navigate
// between routes.
class ProfileLink extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      modal: false,
    };

    // Bindings
    this.logOut = this.logOut.bind(this);
    this.toggleModal = this.toggleModal.bind(this);
  }

  logOut() {
    const { dispatch } = this.props;
    dispatch(userActions.logout());
  }

  toggleModal() {
    this.setState({ modal: !this.state.modal });
  }

  submitInsReq(e) {
    e.preventDefault();
  }

  render() {
    const { user } = this.props;

    // Generat username
    let name = user.name + ' ' + user.lastname;
    name = name.trim().length === 0 ? user.email : name;

    return (
      <div>
        <div className='text-right'>
          <div className='float-left'>
            <Link to='/profile' className='btn btn-secondary'>
              <small>{name}</small>{' '}
              <FontAwesomeIcon className='ml-2' icon='user-alt' />
            </Link>

            {/* <small className="text-muted">({ user.email })</small> */}
            {/* {user.role !== 'student' && <small className="d-block text-muted text-capitalize">{user.role}</small>} */}
            {user.role !== 'student' && (
              <button
                className='btn btn-outline-primary ml-3'
                onClick={this.logOut}>
                Sign out
              </button>
            )}
          </div>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  const { user } = state.authentication;
  return {
    user,
  };
}

export default connect(mapStateToProps)(ProfileLink);
