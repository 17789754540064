import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { connect } from 'react-redux';
// import numeral from 'numeral';
// import moment from 'moment';

// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

// Actions
// import { usersActions } from '../../actions';

import Header from '../../components/Header';
import { UsersList } from './list';
import UserItem from './item';

class UsersPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      items: [],
    };

    // Bindings
  }

  render() {
    const { match } = this.props;

    return (
      <div>
        <Header />

        <Switch>
          <Route exact path={match.path} component={UsersList} />
          <Route exact path={match.path + '/new'} component={UserItem} />
          <Route exact path={match.path + '/:_id'} component={UserItem} />
        </Switch>
      </div>
    );
  }
}

function mapStateToProps(state) {
  const { users } = state;
  const { items } = users;
  return {
    items,
  };
}

export default connect(mapStateToProps)(UsersPage);
