import React from 'react'
import { Editor, EditorState, RichUtils, ContentState, CompositeDecorator, convertFromHTML } from 'draft-js';
import {stateToHTML} from 'draft-js-export-html';

let draftToHTMLoptions = {
    entityStyleFn: (entity) => {
      const entityType = entity.get('type').toLowerCase();
      if (entityType === 'link') {
        const data = entity.getData();
        return {
          element: 'a', attributes: { href: data.url, target:'_blank' }
        };
      } 
    }
  };

class MiniEditor extends React.Component {

    constructor(props) {
        super(props);

        const { value } = this.props

        const decorator = new CompositeDecorator([
            {
              strategy: findLinkEntities,
              component: Link,
            },
        ]);

        const blocksFromHTML = convertFromHTML( value || '' );
        const state = ContentState.createFromBlockArray(
            blocksFromHTML.contentBlocks,
            blocksFromHTML.entityMap,
        );
        
        this.state = {
            editorState: state.getBlockMap().first() ? EditorState.createWithContent(state, decorator) : EditorState.createEmpty(decorator),
            showURLInput: false,
            urlValue: ''
        };

        // Bindings
        this.onChange = this._onChange.bind(this);
        this.handleReturn = this.handleReturn.bind(this);
        this.onURLChange = (e) => this.setState({urlValue: e.target.value});
        this.toggleInlineStyle = this._toggleInlineStyle.bind(this);
        this.promptForLink = this._promptForLink.bind(this);
        this.confirmLink = this._confirmLink.bind(this);
        this.onLinkInputKeyDown = this._onLinkInputKeyDown.bind(this);
        this.focus = () => this.refs.editor.focus();
    }

    _onChange( editorState ) {
        // const editor = this.refs.editor
        this.setState({ editorState }, () => {
            if( this.props.onUpdate ){
                const html = stateToHTML( editorState.getCurrentContent(), draftToHTMLoptions )
                console.log(html)
                this.props.onUpdate && this.props.onUpdate( html )
            }
        });
    }

    handleReturn(e) {
        const { editorState } = this.state;
        if (e.shiftKey) {
          this.setState({ editorState: RichUtils.insertSoftNewline(editorState) });
          return 'handled';
        }
        return 'not-handled';
    }

    _toggleInlineStyle(inlineStyle) {
        this.onChange(
          RichUtils.toggleInlineStyle(
            this.state.editorState,
            inlineStyle
          )
        );
    }

    _promptForLink(e) {
        e.preventDefault();
        const { editorState } = this.state;
        const selection = editorState.getSelection();
        if (!selection.isCollapsed()) {
          const contentState = editorState.getCurrentContent();
          const startKey = editorState.getSelection().getStartKey();
          const startOffset = editorState.getSelection().getStartOffset();
          const blockWithLinkAtBeginning = contentState.getBlockForKey(startKey);
          const linkKey = blockWithLinkAtBeginning.getEntityAt(startOffset);
          let url = '';
          if (linkKey) {
            const linkInstance = contentState.getEntity(linkKey);
            url = linkInstance.getData().url;
          }
          this.setState({
            showURLInput: true,
            urlValue: url,
          }, () => {
            setTimeout(() => this.refs.url.focus(), 0);
          });
        }
    }

    _confirmLink(e) {
        e.preventDefault();
        const {editorState, urlValue} = this.state;
        const contentState = editorState.getCurrentContent();
        const contentStateWithEntity = contentState.createEntity(
          'LINK',
          'MUTABLE',
          {url: urlValue}
        );
        const entityKey = contentStateWithEntity.getLastCreatedEntityKey();
        const newEditorState = EditorState.set(editorState, { currentContent: contentStateWithEntity });
        this.setState({
          editorState: RichUtils.toggleLink(
            newEditorState,
            newEditorState.getSelection(),
            entityKey
          ),
          showURLInput: false,
          urlValue: '',
        }, () => {
          setTimeout(() => this.refs.editor.focus(), 0);
        });
      }

    _onLinkInputKeyDown(e) {
        if (e.which === 13) {
          this._confirmLink(e);
        }
    }

    render() {

        const { editorState, showURLInput } = this.state

        const urlInput = showURLInput ? <div>
            <input
                onChange={this.onURLChange}
                ref="url"
                type="text"
                value={this.state.urlValue}
                onKeyDown={this.onLinkInputKeyDown}
                />
            <button onMouseDown={this.confirmLink}>
            Confirm
            </button>
        </div> : null;

        return (
            <div className="card" style={{ padding: '5px' }}>

                <div className="">
                    <InlineStyleControls
                        editorState={editorState}
                        onToggle={this.toggleInlineStyle} />
                    <button
                        className="btn btn-sm btn-light"
                        onMouseDown={ this.promptForLink }
                        style={{ marginRight: 10 }}>
                        Add Link
                    </button>
                </div>

                <hr style={{ margin: '8px 0 0 0' }} />

                { urlInput }

                <div onClick={this.focus} className="pl-1 pr-1 pt-1 pb-1" style={{ overflow: 'auto', height: '80px' }}>
                    <Editor 
                        ref="editor"
                        editorState={ editorState } 
                        handleReturn={this.handleReturn}
                        onChange={ this.onChange } />
                </div>

            </div>
        );
    }

}
export default MiniEditor



const INLINE_STYLES = [
    { label: 'Bold', style: 'BOLD' },
    { label: 'Italic', style: 'ITALIC' }
];
const InlineStyleControls = (props) => {

    const currentStyle = props.editorState.getCurrentInlineStyle();
    
    return INLINE_STYLES.map((type) =>
        <StyleButton
            key={type.label}
            active={currentStyle.has(type.style)}
            label={type.label}
            onToggle={props.onToggle}
            style={type.style}
            />
    );

};


class StyleButton extends React.Component {
    constructor() {
      super();
      this.onToggle = (e) => {
        e.preventDefault();
        this.props.onToggle(this.props.style);
      };
    }
    render() {
      let className = 'btn btn-sm btn-light mr-2';
      if (this.props.active) {
        className += ' btn btn-sm btn-light mr-2 active';
      }
      return (
        <button className={className} onMouseDown={this.onToggle}>
          {this.props.label}
        </button>
      );
    }
  }


  function findLinkEntities(contentBlock, callback, contentState) {
    contentBlock.findEntityRanges(
      (character) => {
        const entityKey = character.getEntity();
        return (
          entityKey !== null &&
          contentState.getEntity(entityKey).getType() === 'LINK'
        );
      },
      callback
    );
  }

  const Link = (props) => {
    const {url} = props.contentState.getEntity(props.entityKey).getData();
    return (
      <a href={url}>
        {props.children}
      </a>
    );
  };