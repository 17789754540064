import { userConstants } from '../constants';
import { userService } from '../services';
import { alertActions } from './';
import { history } from '../helpers';

export const userActions = {
  login,
  magiclogin,
  logout,
  register,
  requestPassword,
  getAll,
  update,
  setRegError,
  userStatus,
  delete: _delete,
};

function login(email, password) {
  return (dispatch) => {
    dispatch(request({ email }));
    userService.login(email, password).then(
      (user) => {
        dispatch(success(user));
        // Need to refactor this!
        // Maybe using redux-thunk??
        // setTimeout(() => history.push('/'), 250)
      },
      (error) => {
        dispatch(failure(error));
        // dispatch(alertActions.error(error));
      }
    );
  };

  function request(user) {
    return { type: userConstants.LOGIN_REQUEST, user };
  }
  function success(user) {
    return { type: userConstants.LOGIN_SUCCESS, user };
  }
  function failure(error) {
    return { type: userConstants.LOGIN_FAILURE, error };
  }
}

function magiclogin(data) {
  return (dispatch) => {
    dispatch(request(data));
    userService.magiclogin(data).then(
      (user) => {
        user.magic = true;
        dispatch(success(user));
        // Need to refactor this!
        // Maybe using redux-thunk??
        // setTimeout(() => history.push('/'), 250)
      },
      (error) => {
        dispatch(failure(error));
        // dispatch(alertActions.error(error));
      }
    );
  };

  function request(user) {
    return { type: userConstants.LOGIN_REQUEST, user };
  }
  function success(user) {
    return { type: userConstants.LOGIN_SUCCESS, user };
  }
  function failure(error) {
    return { type: userConstants.LOGIN_FAILURE, error };
  }
}

function logout() {
  return (dispatch) => {
    dispatch({ type: userConstants.LOGOUT_REQUEST });
    userService.logout().then((user) => {
      dispatch({ type: userConstants.LOGOUT });
      history.push('/login');
    });
  };
}

function register(credentials) {
  return (dispatch) => {
    dispatch(request(credentials));

    userService.register(credentials).then(
      (user) => {
        dispatch(success(user));
        dispatch(login(credentials.email, credentials.password));
        // history.push('/login');
        // dispatch(alertActions.success('Registration successful'));
      },
      (error) => {
        console.log(error);
        dispatch(failure(error));
        // dispatch(alertActions.error(error));
      }
    );
  };

  function request(user) {
    return { type: userConstants.REGISTER_REQUEST, user };
  }
  function success(user) {
    return { type: userConstants.REGISTER_SUCCESS, user };
  }
  // function success(user) { return { type: userConstants.LOGIN_SUCCESS, user } }
  function failure(error) {
    return { type: userConstants.REGISTER_FAILURE, error };
  }
}

function requestPassword(email) {
  return (dispatch) => {
    // dispatch(request({ email }));
    userService.requestPassword(email).then(
      (user) => {
        dispatch(success(user));
        // Need to refactor this!
        // Maybe using redux-thunk??
        // setTimeout(() => history.push('/'), 250)
      },
      (error) => {
        dispatch(failure(error));
        // dispatch(alertActions.error(error));
      }
    );
  };

  // function request(user) { return { type: userConstants.REQUESTPASS_REQUEST, user } }
  function success(user) {
    return { type: userConstants.REQUESTPASS_SUCCESS, user };
  }
  function failure(error) {
    return { type: userConstants.REQUESTPASS_FAILURE, error };
  }
}

function setRegError(error) {
  return { type: userConstants.REGISTER_FAILURE, error };
}

function userStatus() {
  return (dispatch) => {
    userService
      .userStatus()
      .then((user) => {
        return dispatch(success(user));
      })
      .catch((error) => dispatch({ type: userConstants.LOGOUT }));
  };
  function success(user) {
    return { type: userConstants.LOGIN_SUCCESS, user };
  }
}

function getAll() {
  return (dispatch) => {
    dispatch(request());
    userService.getAll().then(
      (users) => dispatch(success(users)),
      (error) => dispatch(failure(error))
    );
  };

  function request() {
    return { type: userConstants.GETALL_REQUEST };
  }
  function success(users) {
    return { type: userConstants.GETALL_SUCCESS, users };
  }
  function failure(error) {
    return { type: userConstants.GETALL_FAILURE, error };
  }
}

// prefixed function name with underscore because delete is a reserved word in javascript
function update(user) {
  user.role = 'administrator';
  return (dispatch) => {
    userService.update(user).then(
      (user) => {
        user.magic = true;
        dispatch(success(user));
        dispatch(alertActions.success('User profile updated.'));
        setTimeout(() => dispatch(alertActions.clear()), 3000);
      },
      (error) => dispatch(failure(error))
    );
  };

  // function request(id) { return { type: userConstants.DELETE_REQUEST, id } }
  function success(user) {
    return { type: userConstants.UPDATE_PROFILE_SUCCESS, user };
  }
  function failure(error) {
    return { type: userConstants.UPDATE_PROFILE_FAILURE, error };
  }
}

// prefixed function name with underscore because delete is a reserved word in javascript
function _delete(id) {
  return (dispatch) => {
    dispatch(request(id));

    userService.delete(id).then(
      (user) => {
        dispatch(success(id));
      },
      (error) => {
        dispatch(failure(id, error));
      }
    );
  };

  function request(id) {
    return { type: userConstants.DELETE_REQUEST, id };
  }
  function success(id) {
    return { type: userConstants.DELETE_SUCCESS, id };
  }
  function failure(id, error) {
    return { type: userConstants.DELETE_FAILURE, id, error };
  }
}
