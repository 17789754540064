import React from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';

// import { Modal, ModalBody } from 'reactstrap';
// import posed from 'react-pose';

import logo from '../assets/icon-gray.png'; // relative path to image
// import logoWide from '../assets/simcase_wide.png' // relative path to image

import ProfileLink from './ProfileLink';

/* FontAwesome */
import { library } from '@fortawesome/fontawesome-svg-core';
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faTimesCircle,
  faInfo,
  faRoute,
  faBars,
  faListAlt,
  faPowerOff,
  faUserFriends,
  faTachometerAlt,
  faTrashAlt,
  faPlusCircle,
  faCheck,
  faCheckCircle,
  faUserAlt,
} from '@fortawesome/free-solid-svg-icons';
// import { } from '@fortawesome/free-regular-svg-icons'

// Add FontAwesome icons
library.add([
  faTimesCircle,
  faInfo,
  faRoute,
  faBars,
  faListAlt,
  faPowerOff,
  faUserAlt,
  faUserFriends,
  faTachometerAlt,
  faTrashAlt,
  faPlusCircle,
  faCheck,
  faCheckCircle,
]);

// The Header creates links that can be used to navigate
// between routes.
class HeaderStudent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
      about: false,
    };

    // Bindings
    this.toggleMenu = this.toggleMenu.bind(this);
    this.toggleAbout = this.toggleAbout.bind(this);
  }

  toggleMenu() {
    this.setState({ open: !this.state.open });
  }

  toggleAbout() {
    this.setState({ about: !this.state.about, open: false });
  }

  render() {
    // const { user } = this.props;

    return (
      <header className='app-header' style={{ margin: '0 -15px' }}>
        <nav id='main-menu' className='navbar border-bottom-0'>
          <Link className='navbar-brand title mr-2' to='/dashboard'>
            <img
              src={logo}
              className='logo'
              style={{ height: '30px', marginRight: '10px', marginTop: '-9px' }}
              alt='logo'
            />
          </Link>

          <div className='ml-auto mr-3'>
            <ProfileLink />
          </div>

          {/* <div className="menu-toggler float-right text-primary mr-3" onClick={ this.toggleMenu }>
                    <FontAwesomeIcon icon="bars" size="2x" style={{ cursor: 'pointer' }} />
                </div>  */}
        </nav>
      </header>
    );
  }
}

function mapStateToProps(state) {
  const { user } = state.authentication;
  return {
    user,
  };
}

export default connect(mapStateToProps)(HeaderStudent);
