import React from 'react';
import { Redirect, Link } from 'react-router-dom';
import { connect } from 'react-redux';
// import queryString from 'query-string';

// import { playerActions, gameActions } from '../../actions';
// import { setupSocket, enterGame } from '../../helpers';

// import posed, { PoseGroup } from 'react-pose';

// import scLogo from '../assets/icon.png';
import scLogoWide from '../assets/simcase_wide.png';
// import appLogo from '../assets/app_icon.png';

import RecoverForm from '../components/RecoverForm'

class RecoveryPage extends React.Component {

    constructor(props){
        super(props);
        this.state = {
            data: null
        }
    }

    render() {

        const { user } = this.props;

        if( user && user.token )
            return <Redirect to="/dashboard" />

        const isMobile = window.innerWidth < 500 ? true : false

        return (
            <div className="row bgcolorsx">

                <div className="col-12 col-md-4 ml-md-auto mr-md-auto text-center" style={{ height: '100vh' }}>

                    <div className={ !isMobile ? 'v-center' : '' }>
                        <div className={ !isMobile ? 'card shadow' : 'card border-0' }>
                            <div className={ !isMobile ? 'card-body p-5' : 'card-body p-3 pt-5' }>
                                <img src={scLogoWide} className="mb-3 img-fluid" style={{ width: '120px' }} alt="SimCase" />
                                <h3 className="mt-2 mb-4">Access Via Email</h3>
                                <RecoverForm />
                            </div>
                        </div>
                    </div>

                </div>
                
            </div>
        );

    }
}

function mapStateToProps(state) {
    const { authentication } = state;
    const { user } = authentication;
    return {
        user
    };
}

export default connect(mapStateToProps)(RecoveryPage);