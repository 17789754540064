import React from 'react'
import { connect } from 'react-redux';
import queryString from 'query-string';


// Actions
import { userActions, alertActions } from '../actions'

import Header from '../components/Header';
import HeaderPublic from '../components/HeaderPublic';

class ContactPage extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            user: this.props.user ? {...this.props.user} : {},
            name: '',
            lastname: '',
            email: '',
            message: ''
        }
        // Bindings 
        this.handleChange = this.handleChange.bind(this)
        this.handleSubmit = this.handleSubmit.bind(this)
    }

    componentDidMount() {
        const { dispatch } = this.props
        const query = queryString.parse(window.location.search);
        if(query && query.m)
            this.setState({ message: query.m })
        dispatch( alertActions.clear() )
    }

    componentDidUpdate(prevProps){
        const { user } = this.props;
        if( user && user.email !== this.state.email )
            this.setState({ email: user.email })
        if( user && user.name !== this.state.name )
            this.setState({ name: user.name })
        if( user && user.lastname !== this.state.lastname )
            this.setState({ lastname: user.lastname })
    }

    handleChange(e){
        let state = {...this.state}
        state[e.target.name] = e.target.value
        this.setState(state)
    }

    handleSubmit(e){
        e.preventDefault()
        const { dispatch } = this.props
        const { name, lastname, email, message } = this.state

        // Validations
        // console.log(name, lastname, email, message)
        // if( user.password && user.password.length > 0 && user.password !== user.repassword )
        //     return this.setState({ error: 'Password and password confirmation do not match.' })
        // dispatch( userActions.update(user) )

        fetch(`${process.env.REACT_APP_API_URL}/public/contact`, { 
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ name, lastname, email, message })
         })
        .then( res => res.json() )
        .then( data => {
            if( data.body.id ){
                dispatch( alertActions.success('Thank you for reaching out. We will respond shortly.') )
                setTimeout(() => dispatch(alertActions.clear()), 3000);
                this.setState({ sent: true, name: '', lastname: '', email: '', message: '' })
            }
        })
        .catch( error => console.log(error) )

    }

    render() {

        const { alert, loggedIn, user } = this.props
        const { error, name, lastname, email, message } = this.state

        return <div>

            { !loggedIn && <HeaderPublic /> }
            { loggedIn && <Header /> }

            <div className="row">
                <div className="col-10 offset-1">

                    <h2 className="text-center mt-5 mb-5">
                        Contact Us
                    </h2>

                    <hr/>

                    <div className="row mt-5 mb-5">
                        <div className="col-12 col-lg-6 offset-lg-3">

                            { error && <div className="alert alert-danger text-center">{ error }</div> }

                            <form onSubmit={ this.handleSubmit }>
                                <div className="form-group">
                                    <input name="name" className="form-control form-control-lg" placeholder="Name" value={name} onChange={ this.handleChange } required />
                                </div>
                                <div className="form-group">
                                    <input name="lastname" className="form-control form-control-lg" placeholder="Surname" value={lastname} onChange={ this.handleChange } required />
                                </div>
                                <div className="form-group">
                                    <input name="email" type="email" className="form-control form-control-lg" placeholder="email" value={email} onChange={ this.handleChange } required />
                                </div>
                                <div className="form-group">
                                    <textarea name="message" className="form-control form-control-lg" placeholder="How can we help you get started?" rows="3" value={message} onChange={ this.handleChange } required />
                                </div>
                                <div className="form-group">
                                    <button className="btn btn-primary btn-lg btn-block">Send</button>
                                </div>
                            </form>

                            </div>
                    </div>

                </div>
            </div>

        </div>
    }

}

function mapStateToProps( state ) {
    const { alert, authentication } = state;
    const { user, loggedIn } = authentication;
    return { 
        alert,
        user,
        loggedIn
    }
}

export default connect(mapStateToProps)(ContactPage)