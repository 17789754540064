import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { connect } from 'react-redux';
// import numeral from 'numeral';
// import moment from 'moment';

// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

// Actions
// import { usersActions } from '../../actions';

import Header from '../../components/Header';
import SchoolsList from './list';
import SchoolItem from './item';

class SchoolsPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};

    // Bindings
  }

  render() {
    const { match } = this.props;

    return (
      <div>
        <Header />

        <Switch>
          <Route exact path={match.path} component={SchoolsList} />
          <Route exact path={match.path + '/new'} component={SchoolItem} />
          <Route exact path={match.path + '/:_id'} component={SchoolItem} />
        </Switch>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {};
}

export default connect(mapStateToProps)(SchoolsPage);
