import React from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';

// Actions
import { userActions } from '../actions';

// The Header creates links that can be used to navigate
// between routes.
class LoginForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      email: '',
      password: '',
      visible: false,
    };

    // Bindings
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.togglePass = this.togglePass.bind(this);
  }

  handleChange(e) {
    const n = e.target.name;
    const v = e.target.value;
    let state = {};
    state[n] = v;
    this.setState(state);
  }

  handleSubmit(e) {
    e.preventDefault();
    const { email, password } = this.state;
    const { dispatch } = this.props;
    if (email && password) {
      dispatch(userActions.login(email, password));
    }
  }

  togglePass() {
    this.setState({ visible: !this.state.visible });
  }

  render() {
    const { authentication } = this.props;
    const { email, password, visible } = this.state;

    const loggingIn = authentication.loggingIn;

    return (
      <form className='row form-signin' onSubmit={this.handleSubmit}>
        <div className='col-12'>
          {authentication.error && (
            <small className='d-block text-danger font-weight-bolder mb-3'>
              {authentication.error}
            </small>
          )}
          {authentication.recover && (
            <div className='d-block text-success font-weight-bolder mb-3'>
              Check your inbox (or spam) for your access link.
            </div>
          )}

          <fieldset
            disabled={loggingIn}
            style={loggingIn ? { opacity: 0.5 } : {}}>
            <input
              name='email'
              className='form-control form-control-lg'
              value={email}
              onChange={this.handleChange}
              placeholder='Email'
              autoFocus
              required
            />

            <div className='mt-3'>
              <input
                type={visible ? 'text' : 'password'}
                id='pass'
                name='password'
                className='form-control form-control-lg'
                value={password}
                onChange={this.handleChange}
                placeholder='Password'
                required
              />
              <span className='pass-switch' onClick={this.togglePass}>
                {visible ? 'HIDE' : 'SHOW'}
              </span>
            </div>
          </fieldset>

          <Link to='/recover' className='mt-4 d-block'>
            <small className='font-weight-bolder'>Access Via Email</small>
          </Link>

          <div className='mt-5 text-center'>
            <small>
              Need help? Visit{' '}
              <a
                className='font-weight-bolder'
                href='https://simcase.help'
                target='_blank'
                rel='noopener noreferrer'>
                simcase.help
              </a>
            </small>
          </div>

          <div className='clearfix mt-4'>
            <button className='btn btn-primary btn-md mt-3 ml-auto mr-auto pr-4 pl-4 float-lg-right'>
              Login
            </button>
            <Link
              to='/register'
              className='btn btn-link btn-sm d-block d-lg-inline font-weight-bolder mt-3 float-lg-left'>
              Create an instructor account
            </Link>
          </div>
        </div>

        <div className='col-sm-10 col-sm-offset-1 mt-3 d-block d-md-none'>
          <small className='d-block text-white mt-5'>
            Developed by SimCase
          </small>
        </div>
      </form>
    );
  }
}

function mapStateToProps(state) {
  const { authentication } = state;
  return {
    authentication,
  };
}

export default connect(mapStateToProps)(LoginForm);
