import React from 'react';
// import { Link, Redirect } from 'react-router-dom'
import { connect } from 'react-redux';

// Actions
import { usersActions } from '../../actions/users';
import { schoolsActions } from '../../actions/schools';

import UserForm from './form';

class Item extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};

    // Bindings
    this.submit = this.submit.bind(this);
  }

  componentDidMount() {
    const { match, dispatch } = this.props;
    if (match.params._id) {
      dispatch(usersActions.fetchOne(match.params._id));
    }
    dispatch(schoolsActions.getSchools());
  }

  submit(item) {
    const { dispatch } = this.props;
    if (item._id) {
      dispatch(usersActions.updateItem(item));
    } else {
      dispatch(usersActions.createItem(item));
    }
  }

  render() {
    const { item, schools, alert, user } = this.props;

    if (!item) return <div className='alert alert-info mt-5'>Loading...</div>;

    return (
      <UserForm
        user={user}
        item={item}
        schools={schools}
        alert={alert}
        onSubmit={this.submit}
      />
    );
  }
}

function mapStateToProps(state, props) {
  const { match } = props;
  const { authentication, users, schools, alert } = state;
  if (match.params._id) {
    return {
      alert,
      user: authentication.user,
      schools: schools.items,
      item: users.items.find((item) => item._id === match.params._id) || null,
    };
  }
  return { schools: schools.items, item: {}, alert, user: authentication.user };
}

export default connect(mapStateToProps)(Item);
