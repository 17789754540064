import { usersConstants } from '../constants/users';
import { alertActions, userActions } from './';
import { authHeader, handleJSONResponse, store, history } from '../helpers';

// Inner Helpers
const checkJSONRequest = (response) => {
  const dispatch = store.dispatch;
  return handleJSONResponse(response, (data, code) => {
    if (code === 401) dispatch(userActions.logout());
    if (code !== 401) history.push('/error?code=403');
  });
};

export const usersActions = {
  fetchOne,
  createItem,
  updateItem,
  getAll,
  delete: _delete,
};

function fetchOne(id) {
  return (dispatch) => {
    fetch(`/api/users/${id}`, {
      method: 'GET',
      headers: authHeader(),
    })
      .then((response) => response.json())
      .then((data) => dispatch(success(data[0] || null)));
  };
  // function request() { return { type: usersConstants.GETALL_REQUEST } }
  function success(user) {
    return { type: usersConstants.FETCHONE_SUCCESS, item: user };
  }
  // function failure(error) { return { type: usersConstants.GETALL_FAILURE, error } }
}

function createItem(item) {
  return (dispatch) => {
    fetch(`/api/users`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json', ...authHeader() },
      body: JSON.stringify(item),
    })
      .then(checkJSONRequest)
      .then((data) => {
        dispatch(success(data || null));
        dispatch(alertActions.success(`User successfully created.`));
        setTimeout(() => dispatch(alertActions.clear()), 3000);
      });
  };
  // function request() { return { type: usersConstants.GETALL_REQUEST } }
  function success(user) {
    return { type: usersConstants.CREATEONE_SUCCESS, item: user };
  }
  // function failure(error) { return { type: usersConstants.GETALL_FAILURE, error } }
}

function updateItem(item) {
  return (dispatch) => {
    fetch(`/api/users/${item._id}`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json', ...authHeader() },
      body: JSON.stringify(item),
    })
      .then(checkJSONRequest)
      .then((data) => {
        dispatch(success(data || null));
        dispatch(alertActions.success(`User successfully updated.`));
        setTimeout(() => dispatch(alertActions.clear()), 3000);
      });
  };
  // function request() { return { type: usersConstants.GETALL_REQUEST } }
  function success(user) {
    return { type: usersConstants.UPDATEONE_SUCCESS, item: user };
  }
  // function failure(error) { return { type: usersConstants.GETALL_FAILURE, error } }
}

function getAll(filters) {
  return (dispatch) => {
    dispatch(request());

    const qstring = Object.keys(filters)
      .filter((key) => !isNaN(filters[key]) || filters[key].length > 0)
      .map((key) => `${key}=${filters[key]}`)
      .join('&');

    fetch(
      `${process.env.REACT_APP_API_URL}/users/all${
        qstring ? '?' + qstring : ''
      }`,
      {
        method: 'GET',
        headers: authHeader(),
      }
    )
      .then(checkJSONRequest)
      .then(
        (users) => dispatch(success(users)),
        (error) => dispatch(failure(error))
      );
  };

  function request() {
    return { type: usersConstants.GETALL_REQUEST };
  }
  function success(users) {
    return { type: usersConstants.GETALL_SUCCESS, users };
  }
  function failure(error) {
    return { type: usersConstants.GETALL_FAILURE, error };
  }
}

// prefixed function name with underscore because delete is a reserved word in javascript
function _delete(id) {
  return (dispatch) => {
    dispatch(request(id));

    fetch(process.env.REACT_APP_API_URL + `/users/${id}`, {
      method: 'DELETE',
      headers: authHeader(),
    }).then(
      (user) => {
        dispatch(success(id));
      },
      (error) => {
        dispatch(failure(id, error));
      }
    );
  };

  function request(id) {
    return { type: usersConstants.DELETE_REQUEST, id };
  }
  function success(id) {
    return { type: usersConstants.DELETE_SUCCESS, id };
  }
  function failure(id, error) {
    return { type: usersConstants.DELETE_FAILURE, id, error };
  }
}
