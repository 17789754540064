import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';

export const sentryInit = () =>
  Sentry.init({
    dsn:
      'https://8324621dd50148f28b8467460cb2a890@o468452.ingest.sentry.io/5496525',
    integrations: [new Integrations.BrowserTracing()],

    // We recommend adjusting this value in production, or using tracesSampler
    // for finer control
    tracesSampleRate: 1.0,
  });
