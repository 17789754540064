import React from 'react'
import { connect } from 'react-redux';
import { Toast, ToastBody, ToastHeader } from 'reactstrap';

class Alert extends React.Component {

    constructor(props) {
        super(props)
        this.state = {}
        // Bindings 
    }

    // componentDidMount() {}

    render() {
        const { alert } = this.props;
        const isOpen = alert && alert.message ? true : false
        return <Toast isOpen={isOpen} 
                className={`position-absolute`} 
                style={{ left: '50%', right: 'auto', bottom: '30px', width: '300px', marginLeft: '-150px' }}>
                <ToastHeader className={`bg-${alert.type} text-white`}>
                    { alert && (alert.title || 'Notification') }
                </ToastHeader>
                <ToastBody>
                    { alert && alert.message }
                </ToastBody>
            </Toast>
    }

}

function mapStateToProps( state ) {
    const { alert } = state;
    return { alert }
}

export default connect(mapStateToProps)(Alert)