import React from 'react';
import { Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
// import queryString from 'query-string';

// import { playerActions, gameActions } from '../../actions';
// import { setupSocket, enterGame } from '../../helpers';

// import posed, { PoseGroup } from 'react-pose';
import { qs } from '../helpers/utils';

import scLogoWide from '../assets/simcase_wide.png';

import AbsoluteRedirect from '../components/AbsoluteRedirect';
import LoginForm from '../components/LoginForm';

class HomePage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    const timeout = setTimeout(() => {
      this.setState({ loading: false }, () => {
        setTimeout(() => this.setState({ finished: true }), 500);
      });
    }, 1000);
    this.setState({ timeout });
  }

  componentWillUnmount() {
    clearTimeout(this.state.timeout);
  }

  render() {
    const { loggedIn, location } = this.props;
    const query = qs(location.search);

    if (loggedIn && query.prev)
      return <AbsoluteRedirect to={decodeURIComponent(query.prev)} />;

    if (loggedIn && location.state && location.state.from)
      return <Redirect to={location.state.from} />;

    if (loggedIn) return <Redirect to='/dashboard' />;

    const isMobile = window.innerWidth < 500 ? true : false;

    return (
      <div className={`row bgcolorsx`}>
        <div
          className='col-12 col-md-4 ml-md-auto mr-md-auto text-center'
          style={{ height: '100vh' }}>
          <div className={!isMobile ? 'v-center' : ''}>
            <div className={!isMobile ? 'card shadow' : 'card border-0'}>
              <div
                className={!isMobile ? 'card-body p-5' : 'card-body p-3 pt-5'}>
                <img
                  src={scLogoWide}
                  className='mb-3 img-fluid'
                  style={{ width: '120px' }}
                  alt='SimCase'
                />
                <h3 className='mt-2 mb-4'>Sign in</h3>
                <LoginForm />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  const { user, loggedIn } = state.authentication;
  return {
    user,
    loggedIn,
  };
}

const connectedHomePage = connect(mapStateToProps)(HomePage);
export { connectedHomePage as HomePage };
